.xHJDEa_container {
  display: block;
}

.xHJDEa_link {
  text-align: center;
}

.xHJDEa_link a {
  color: #8ca659;
  width: 50vw;
  border: 1px solid #848c7366;
  margin-bottom: 20px;
  padding: 20px;
  text-decoration: none;
  display: inline-block;
}

/*# sourceMappingURL=index.433c54e8.css.map */
